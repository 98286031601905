/* eslint-disable jsx-a11y/img-redundant-alt */
import { Grid, InputLabel, OutlinedInput, Stack, Paper, MenuItem, Select, Typography } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singerService } from "../singerService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { singerPayload } from "../singerPayload";
import { Profile } from "../../../shares/Profile";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { videoPayload } from '../videoPayload';
import { delRequest, getRequest, postRequest } from '../../../helpers/api';
import { endpoints } from '../../../constants/endpoints';

export const SingerUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(singerPayload.update);
  const [mediaPayload, setMediaPayload] = useState(videoPayload.store);
  const [profile, setProfile] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const { singer } = useSelector(state => state.singer);

  const [inviteVideo, setInviteVideo] = useState(null);
  const [sliderImage, setSliderImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitSinger = async () => {
    setLoading(true);
    const formData = formBuilder(payload, singerPayload.update);
    await singerService.update(dispatch, params.id, formData);
    setLoading(false);
  }

  const submitSingerVideo = async () => {
    setLoading(true);
    mediaPayload.singer_id = params.id;
    let updateMediaPayload = { ...mediaPayload};
    updateMediaPayload.singer_id = params.id;
    const formData = formBuilder(updateMediaPayload, mediaPayload);
    await postRequest(endpoints.singerVideo, formData);
    const result = await getRequest(`${endpoints.singerVideo}/${params.id}/list`);

    if (result.data) {
      setVideoList(result.data);
    }

    setLoading(false);
  }

  const submitDelVideo = async (id) => {
    setLoading(true);
    await delRequest(`${endpoints.singerVideo}/${id}`);
    const result = await getRequest(`${endpoints.singerVideo}/${params.id}/list`);

    if (result.data) {
      setVideoList(result.data);
    }

    setLoading(false);
  }

  const loadingData = useCallback(async () => {
    setLoading(true);
    await singerService.show(dispatch, params.id);
    const result = await getRequest(`${endpoints.singerVideo}/${params.id}/list`);

    if (result.data) {
      setVideoList(result.data);
    }

    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (singer) {
      setProfile(singer.profile);
      let updatePayload = { ...singer };
      updatePayload.profile = null;
      updatePayload.cover_photo = null;
      updatePayload.slider_image = null;
      updatePayload.invite_video = null;

      setPayload(updatePayload);
      setSliderImage(`${endpoints.image}/${singer.slider_image}`);
      setCoverImage(`${endpoints.image}/${singer.cover_photo}`);
      setInviteVideo(`${endpoints.image}/${singer.invite_video}`);
    }
  }, [singer]);

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>
        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={1} justifyContent="center" alignItems="center" >
                <Profile
                  preview={profile}
                  onSelect={(e) => {
                    payloadHandler(payload, e, 'profile', (updateValue) => {
                      setPayload(updateValue);
                    })
                    setProfile(e);
                  }}
                />
                <ValidationMessage field={"profile"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Name </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={payload.name ? payload.name : ""}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "name",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter Singer Name"
                />
                <ValidationMessage field={"name"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Slider Description </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={payload.slider_description ? payload.slider_description : ""}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "slider_description",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter Slider  Description"
                />
                <ValidationMessage field={"slider_description"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Detail Title </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={payload.detail_title ? payload.detail_title : ""}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "detail_title",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter Detail Title"
                />
                <ValidationMessage field={"detail_title"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Show Slider </InputLabel>
                <Select
                  id="show_slider"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "show_slider",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  value={payload.show_slider ? payload.show_slider : "DISABLE"}
                  name="show_slider"
                >
                  <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                  <MenuItem value="DISABLE"> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"show_slider"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Status </InputLabel>
                <Select
                  id="status"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  value={payload.status ? payload.status : "DISABLE"}
                  name="status"
                >
                  <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                  <MenuItem value="DISABLE"> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"status"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel > Slider Image (290px x 200px)</InputLabel>
                <OutlinedInput
                  id="name"
                  type="file"
                  onChange={(e) => {
                    payloadHandler(
                      payload,
                      e.target.files[0],
                      "slider_image",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )

                    const getImage = URL.createObjectURL(e.target.files[0])
                    setSliderImage(getImage);
                  }}
                />
                <ValidationMessage field={"slider_image"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel > Cover Image (404px x 227px)  </InputLabel>
                <OutlinedInput
                  id="name"
                  type="file"
                  onChange={(e) => {
                    payloadHandler(
                      payload,
                      e.target.files[0],
                      "cover_photo",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )

                    const getImage = URL.createObjectURL(e.target.files[0])
                    setCoverImage(getImage);
                  }}
                />
                <ValidationMessage field={"cover_photo"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Upload Invite Video </InputLabel>
                <OutlinedInput
                  id="invite_video"
                  type="file"
                  placeholder="Upload Video File"
                  onChange={(e) => {
                    payloadHandler(
                      payload,
                      e.target.files[0],
                      "invite_video",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }}
                />
                <ValidationMessage field={"invite_video"} />
              </Stack>
            </Grid>



            {sliderImage && (
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Typography style={{ fontWeight: 600 }}> Slider Image </Typography>
                  <img style={{ width: "100%" }} src={sliderImage} alt='Slider Image' title='Slider Image' />
                </Stack>
              </Grid>
            )}

            {coverImage && (
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Typography style={{ fontWeight: 600 }}> Cover Image </Typography>
                  <img style={{ width: "100%" }} src={coverImage} alt='Slider Image' title='Slider Image' />
                </Stack>
              </Grid>
            )}

            {inviteVideo && (
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Typography style={{ fontWeight: 600 }}> Invite Video </Typography>
                  <video controls width="100%">
                    <source src={`${inviteVideo}`} type="video/mp4" />
                  </video>
                </Stack>
              </Grid>
            )}

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.singer)}
              submit="Update"
              submitClick={submitSinger}
              loading={loading}
            />
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >Video Title </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      mediaPayload,
                      e.target.value,
                      "title",
                      (updateValue) => {
                        setMediaPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter Video Title"
                />
                <ValidationMessage field={"title"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Album Name </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      mediaPayload,
                      e.target.value,
                      "album_name",
                      (updateValue) => {
                        setMediaPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter Album Name"
                />
                <ValidationMessage field={"albunm_name"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Upload File Name </InputLabel>
                <OutlinedInput
                  id="name"
                  type="file"
                  placeholder="Upload Series Video"
                  onChange={(e) => {
                    payloadHandler(
                      mediaPayload,
                      e.target.files[0],
                      "video",
                      (updateValue) => {
                        setMediaPayload(updateValue);
                      }
                    )
                  }}
                />
                <ValidationMessage field={"video"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.singer)}
              submit="Create Video"
              submitClick={submitSingerVideo}
              loading={loading}
            />
          </Grid>

          <Grid container spacing={3} style={{ padding: "20px" }}>
            <h3> Video List </h3>
          </Grid>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: 'wrap'
          }}>
            {videoList.length > 0 && videoList.map((video, index) => {
              return (
                <div key={`video_id_${index}`} style={{
                  width: "calc(100%/3)",
                  marginTop: "20px",
                  padding: "20px"
                }}>
                  <h3 style={{
                    padding: "0px",
                    margin: "0px"
                  }}> {video.title} </h3>
                  <label style={{ fontSize: "12px", fontWeight: "bold", color: "gray" }}> {video.album_name} </label>
                  <video controls width="100%">
                    <source src={`${endpoints.image}/${video.video}`} type="video/mp4" />
                  </video>
                  <button style={{
                    width: "100%",
                    background: "red",
                    color: "#fff",
                    border: "0px",
                    padding: "10px",
                    cursor: "pointer"
                  }} onClick={() => {
                    submitDelVideo(video.id)
                  }}> Delete </button>
                </div>
              )
            })}
          </div>
        </Paper>
      </div>
    </>
  );
};
