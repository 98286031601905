import { paths } from "../../constants/paths"
import { SliderCreate } from "./entry/SliderCreate"
import { SliderUpdate } from "./entry/SliderUpdate"

import { SliderList } from "./list/SliderList"


export const sliderRoutes = [
    {
        id: "slider",
        path: paths.slider,
        element : <SliderList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Slider", url: paths.slider },
                    { label: null, url: null, current : "List" },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "sliderCreate",
        path: paths.sliderCreate,
        element : <SliderCreate />,
        loader: () => {
            return{
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Slider", url: paths.slider },
                    { label: null, url: null, current : "Create" },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "sliderDetail",
        path: `/${paths.slider}/:id`,
        element: <SliderUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Slider", url: paths.slider },
                    { label: null, url: null, current : "Update" },
                ]
            }
        }
    }
]