import { createSlice } from "@reduxjs/toolkit";
import { episodePayload } from "./episodePayload";

const episodeSlice = createSlice({
    name: 'episode',
    initialState: {
        episodes: [],
        episode: null,
        paginateParams : episodePayload.paginateParams,
        total : 0
    },
    reducers: {
        index: (state, action) => {
            state.episodes = action.payload;
            return state;
        },
        update: (state, action) => {
            state.episode = action.payload;
            return state;
        },
        setPaginate: (state, action) => {
            state.paginateParams = action.payload;
            return state;
        }
    }
});

export const { index, update, setPaginate } = episodeSlice.actions;
export default episodeSlice.reducer;