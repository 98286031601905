import React, { useCallback, useEffect, useRef, useState } from "react";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePaginationActions, emptyRows } from "../../../constants/config";
import { Avatar, Box, TableHead, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sliderService } from "../sliderService";
import { sliderPayload } from "../sliderPayload";
import { setPaginate } from "../sliderSlice";
import { endpoints } from "../../../constants/endpoints";
import { getRequest } from "../../../helpers/api";
import { FilterByDate } from "../../../shares/FilterByDate";
import { setDateFilter } from "../../../shares/shareSlice";
import { Status } from "../../../shares/Status";
import { TableCustomizeSetting } from "../../../shares/TableCustomizeSetting";
import { FilterByStatus } from "../../../shares/FilterByStatus";
import { TableSearch } from "../../../shares/TableSearch";
import { NavigateId } from "../../../shares/NavigateId";
import { paths } from "../../../constants/paths";

export const SliderList = () => {
    const { sliders, paginateParams } = useSelector((state) => state.slider);
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [columnIds, setColumnIds] = useState("");
    const [sort, setSort] = useState(true);
    const sliderStatus = useRef(["ALL"]);

    const [columns, setColumns] = useState(sliderPayload.columns);

    /**
     * Event - Paginate Page Change
     * @param {*} event
     */
    const onPageChange = (event, newPage) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: newPage,
            })
        );
    };

    /**
     * Event - Paginate Row Per Page Change
     * @param {*} event
     */
    const onRowPerPageChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: 1,
                per_page: parseInt(event.target.value, 10),
            })
        );
    };

    /**
     * Event - Sorting
     * @param {*} event
     */
    const onHandleSort = (event, label) => {
        setSort(!sort);
        dispatch(
            setPaginate({
                ...paginateParams,
                sort: sort ? "ASC" : "DESC",
                order: label?.toLowerCase(),
            })
        );
    };

    const ColumnSortHandle = (id) => {
        if (columnIds === id) {
            return sort ? "asc" : "desc";
        }
    };

    /**
     * Event - Search
     * @param {*} event
     */
    const onSearchChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                search: event,
            })
        );
    };

    /**
     * On Change Filter
     * @param {*} event
     */
    const onFilter = (e) => {
        console.log(e);
        let updatePaginateParams = { ...paginateParams };

        if (e?.target?.value === "ALL") {
            updatePaginateParams.filter = "";
            updatePaginateParams.value = "";
        } else {
            updatePaginateParams.filter = "status";
            updatePaginateParams.value = e?.target?.value;
        }

        dispatch(setPaginate(updatePaginateParams));
        // dispatch(setStatusFilter(e));
    };

    const onFilterByDate = (e) => {
        let updatePaginateParams = { ...paginateParams };

        updatePaginateParams.start_date = e.startDate
            ? e.startDate.toISOString().split("T")[0]
            : "";
        updatePaginateParams.end_date = e.endDate
            ? e.endDate.toISOString().split("T")[0]
            : "";

        console.log(updatePaginateParams);

        dispatch(setDateFilter(e));
        dispatch(setPaginate(updatePaginateParams));
    };

    const loadingData = useCallback(async () => {
        const result = await sliderService.index(dispatch, paginateParams);
        if (result.status === 200) {
            setTotal(
                result?.data?.total ? result.data.total : result.data.length
            );
        }
    }, [dispatch, paginateParams]);

    /**
     * loading Slider Status
     */
    const loadingStatus = useCallback(async () => {
        const sliderStatusResponse = await getRequest(
            `${endpoints.status}?type=slider`
        );

        if (sliderStatusResponse.status === 200) {
            sliderStatus.current = sliderStatus.current.concat(
                sliderStatusResponse.data.slider
            );
        }
    }, []);

    // useEffect(() => {
    //     loadingStatus();
    // }, [loadingStatus]);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    const IconRender = ({ dataSource }) => {
        return (
            <Avatar alt="icon" src={dataSource ? `${endpoints.image}/${dataSource.image}` : null} />
        )
    }

    return (
        <div>
            <Breadcrumb />

            <Paper
                sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}
            >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table sx={{ minWidth: 500 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        marginTop={1}
                                    >
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            gap={2}
                                        >
                                            {/* <FilterByStatus status={sliderStatus} onFilter={onFilter} />

                      <FilterByDate onFilter={onFilterByDate} />

                      <TableCustomizeSetting payload={sliderPayload} setColumns={setColumns} /> */}
                                        </Box>

                                        <TableSearch
                                            paginateParams={paginateParams}
                                            onSearchChange={onSearchChange}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column) => {
                                    
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                <TableSortLabel
                                                    active={true}
                                                    direction={ColumnSortHandle(column.id)}
                                                    onClick={(e) => {
                                                        onHandleSort(e, column.id);
                                                        setColumnIds(column.id);
                                                    }}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    
                                })}
                            </TableRow>

                        </TableHead>
                            <TableBody>
                                {sliders.map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            const switchCase = ({ column, value }) => {
                                                switch (column.id) {
                                                    case "title":
                                                        return (
                                                            <NavigateId
                                                                url={`${paths.slider}/${row.id}`}
                                                                value={value}
                                                            />
                                                        );
                                                    case "image":
                                                        return <IconRender dataSource={value} />;
                                                    default:
                                                        return value;
                                                }
                                            };

                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {switchCase({ column, value })}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                                {emptyRows(paginateParams.page, paginateParams.rowsPerPage, sliders) > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={columns.length} />
                                    </TableRow>
                                )}
                            </TableBody>

                    </Table>
                </TableContainer>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    sx={{
                        width: "100%",
                    }}
                >
                    <TableRow>
                        <TableCell>
                            <TablePagination
                                sx={{
                                    width: "100%",
                                }}
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={total}
                                rowsPerPage={paginateParams.per_page}
                                page={paginateParams ? paginateParams.page - 1 : 0}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowPerPageChange}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableCell>
                    </TableRow>
                </Box>
            </Paper>
        </div>
    );
};
