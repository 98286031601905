import {
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Paper
} from "@mui/material";
import { paths } from "../../../constants/paths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { singerService } from "../singerService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import { singerPayload } from "../singerPayload";
import { Profile } from "../../../shares/Profile";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";

export const SingerCreate = () => {
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState(singerPayload.store);
    const [sliderImage, setSliderImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitSinger = async () => {
        setLoading(true);
        const formData = formBuilder(payload, singerPayload.store);
        await singerService.store(formData, dispatch);
        setLoading(false);
        navigate(paths.singer);
    };

    return (
        <>
            <div className=" grid">
                <div className="col-12">
                    <Breadcrumb />
                </div>

                <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Stack
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Profile
                                    preview={payload.profile ? payload.profile.image : null}
                                    onSelect={(e) => payloadHandler(payload, e, 'profile', (updateValue) => {
                                        setPayload(updateValue);
                                    })}
                                />
                                <ValidationMessage field={"profile"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Name (required)
                                </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "name",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="name"
                                    placeholder="Enter Singer Name"
                                />
                                <ValidationMessage field={"name"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Slider Description (required)
                                </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "slider_description",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="name"
                                    placeholder="Enter Slider Description"
                                />
                                <ValidationMessage field={"slider_description"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Detail Title (required)
                                </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "detail_title",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="name"
                                    placeholder="Enter Detail Title"
                                />
                                <ValidationMessage field={"detail_title"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name"> Upload Slider Image (290px x 200px) </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="file"
                                    onChange={(e) => {
                                        payloadHandler(
                                            payload,
                                            e.target.files[0],
                                            "slider_image",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        );

                                        const getImage = URL.createObjectURL(e.target.files[0])
                                        setSliderImage(getImage);
                                    }}
                                    name="name"
                                    placeholder="Enter Slider Image"
                                />
                                <ValidationMessage field={"slider_image"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name"> Upload Cover Image (404px x 227px) </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="file"
                                    onChange={(e) => {
                                        payloadHandler(
                                            payload,
                                            e.target.files[0],
                                            "cover_photo",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        );

                                        const getImage = URL.createObjectURL(e.target.files[0])
                                        setCoverImage(getImage);
                                    }}
                                    name="name"
                                    placeholder="Enter Cover Image"
                                />
                                <ValidationMessage field={"cover_photo"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {sliderImage && (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img style={{width: "100%"}} src={sliderImage} alt="Slider Image" title="Slider Image" />
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {coverImage && (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img  style={{width: "100%"}} src={coverImage} alt="Slider Image" title="Slider Image" />
                            )}
                        </Grid>

                        <FormMainAction
                            cancel="Cancle"
                            cancelClick={() => navigate(paths.singer)}
                            submit="Create"
                            submitClick={submitSinger}
                            loading={loading}
                        />
                    </Grid>
                </Paper>
            </div>
        </>
    );
};
