
export const env = 2;
const baseURLs = [
    "http://localhost:8000/api", "http://hnk-api.innoscript.co/api", "https://api.refreshyourmusichnk.com/api"
];

const imageURLs = [
   "http://localhost:8000", "http://hnk-api.innoscript.co", "https://api.refreshyourmusichnk.com"
];


export const baseURL = baseURLs[env];
export const imageURL = imageURLs[env];

export const endpoints = {
    login: "auth/login",
    admin: "admin",
    episode: "episode",
    singer: "singer",
    singerVideo: "video-in-singer",
    song: "song",
    genre: "genre",
    outlet: "outlet",
    slider: "promotion-slider",
    user: "user",
    singerSlider: "singer-song",
    singerInSong: "singer-in-song",
    eventSlider: "event",
    image: `${imageURL}/storage/images`,
    audio: `${imageURL}/storage/audio`,
    video: `${imageURL}/storage/video`,
    dashboardUserVote: "count/user-vote",
    dashboardTotalUser: "count/user"
};
