export const videoPayload = {
    update: {
        singer_id: "",
        title: "",
        album_name: "",
        status: "",
    },
    store: {
        singer_id: "",
        title: "",
        video: "",
        album_name: "",
    },
    columns: [
        { id: "video", label: "Video", minWidth: 250 },
        { id: "title", label: "Title", minWidth: 150 },
        { id: "album_name", label: "Album Name", minWidth: 300 },
        { id: "status", label: "Status", minWidth: 150 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "name",
        search: "",
        order: "id",
        sort: "DESC",
        value: "",
        start_date: "",
        end_date: "",
    },
};
