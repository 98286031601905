import {
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Paper,
  Select,
  MenuItem,
} from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { genreService } from "../genreService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { genrePayload } from "../genrePayload";
import { Profile } from "../../../shares/Profile";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';

export const GenreUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(genrePayload.update);
  const [icon, setIcon] = useState(null);
  const { genre } = useSelector(state => state.genre);
  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitGenre = async () => {
    setLoading(true);
    const formData = formBuilder(payload, genrePayload.update);
    await genreService.update(dispatch, params.id, formData);
    setLoading(false);
    navigate(paths.genre);
  }

  const loadingData = useCallback(async () => {
    setLoading(true);
    await genreService.show(dispatch, params.id);
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  useEffect(() => {
    if(genre) {
      setIcon(genre.icon && genre.icon.image ? genre.icon.image : "");
      let updatePayload = {...genre};
      updatePayload.icon = null;
      setPayload(updatePayload);
    }
  },[genre]);
  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack spacing={1} justifyContent="center" alignItems="center" >
                <Profile
                  preview={icon}
                  onSelect={(e) => {
                    payloadHandler(payload, e, 'icon', (updateValue) => {
                      setPayload(updateValue);
                    })
                    setIcon(e);
                  }}
                />
                <ValidationMessage field={"icon"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel >Name </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={payload.name ? payload.name : ""}
                  onChange={(e) => {
                    payloadHandler(
                      payload,
                      e.target.value,
                      "name",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }}
                  name="name"
                  placeholder="Enter Genre Name"
                />
                <ValidationMessage field={"name"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel >Rate </InputLabel>
                <OutlinedInput
                  id="rate"
                  type="text"
                  value={payload.rate ? payload.rate : ""}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "rate",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="rate"
                  placeholder="Enter Genre Rate"
                />
                <ValidationMessage field={"rate"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel >Color </InputLabel>
                <OutlinedInput
                  id="color"
                  type="color"
                  value={payload.color ? payload.color : ""}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "color",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="color"
                  placeholder="Choose Color"
                />
                <ValidationMessage field={"color"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel >Auto Rate </InputLabel>
                <Select
                  id="auto_rate"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "auto_rate",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  value={payload.auto_rate ? payload.auto_rate : "DISABLE"}
                  name="auto_rate"
                >
                  <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                  <MenuItem value="DISABLE"> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"auto_rate"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel >Status </InputLabel>
                <Select
                  id="status"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  value={payload.status ? payload.status : ""}
                  name="status"
                >
                  <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                  <MenuItem value="DISABLE"> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"status"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.genre)}
              submit="Update"
              submitClick={submitGenre}
              loading={loading}
            />

          </Grid>

        </Paper>

      </div>
    </>
  );
};
