import { createSlice } from "@reduxjs/toolkit";
import { sliderPayload } from "./sliderPayload";

const sliderSlice = createSlice({
    name: 'slider',
    initialState: {
        sliders: [],
        slider: null,
        paginateParams : sliderPayload.paginateParams,
        total : 0
    },
    reducers: {
        index: (state, action) => {
            state.sliders = action.payload;
            return state;
        },
        update: (state, action) => {
            state.slider = action.payload;
            return state;
        },
        setPaginate: (state, action) => {
            state.paginateParams = action.payload;
            console.log(action.payload)
            return state;
        }
    }
});

export const { index, update, setPaginate } = sliderSlice.actions;
export default sliderSlice.reducer;