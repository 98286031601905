export const paths = {
    dashboard: "/dashboard",

    singer: "/singer",
    singerCreate: "/singer/new",
    singerVideo: "singer/video",

    episode: "/episode",

    song: "/song",
    songCreate: "/song/new",
 
    genre: "/genre",
    genreCreate: "/genre/new",
   
    outlet: "/outlet",
    outletCreate: "/outlet/new",

    slider: "/slider",
    sliderCreate: "/slider/new",

    singerSlider: "/singer-slider",
    singerSliderCreate: "/singer-slider/new",

    eventSlider: "/event-slider",
    eventSliderCreate: "/event-slider/new",

    admin: "/admin",
    adminCreate: "/admin/new",
    adminLogout: "/auth/login",

    promotion: "/promotion",
    promotionCreate: "/promotion/new",
    promotionDetail: "/promotion/:id",

    memberCard: "/memberCard",
    memberCardCreate: "/memberCard/new",
    memberCardDetail: "/memberCard/:id",

    member: "/member",
    memberCreate: "/member/new",
    memberDetail: "/member/:id",

    memberOrder: "/memberOrder",
    memberOrderDetail: "/memberOrder/:id",

    discount: "/discount",
    discountCreate: "/discount/new",
    discountDetail: "/discount/:id",

    user: "/user",
    userCreate: "/user/new",
    userDetail: "user/:id",


};
