import { Grid, InputLabel, OutlinedInput, Stack, Paper, MenuItem, Select } from "@mui/material";
import { paths } from "../../../constants/paths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { genreService } from "../genreService";
import { payloadHandler } from "../../../helpers/handler";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import { genrePayload } from "../genrePayload";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { Profile } from "../../../shares/Profile";

export const GenreCreate = () => {
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState(genrePayload.store);
    const [icon, setIcon] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitGenre = async () => {
        setLoading(true);
        const formData = formBuilder(payload, genrePayload.store);
        await genreService.store(formData, dispatch);
        setLoading(false);
        // navigate(paths.genre)
    };

    return (
        <>
            <div className=" grid">
                <div className="col-12">
                    <Breadcrumb />
                </div>

                <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Stack spacing={1} justifyContent="center" alignItems="center" >
                                <Profile
                                    preview={icon}
                                    onSelect={(e) => {
                                        payloadHandler(payload, e, 'icon', (updateValue) => {
                                            setPayload(updateValue);
                                        })
                                        setIcon(e);
                                    }}
                                />
                                <ValidationMessage field={"icon"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Stack spacing={1}>
                                <InputLabel >Name </InputLabel>
                                <OutlinedInput
                                    id="name"
                                    type="text"
                                    value={payload.name ? payload.name : ""}
                                    onChange={(e) => {
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "name",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }}
                                    name="name"
                                    placeholder="Enter Genre Name"
                                />
                                <ValidationMessage field={"name"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Stack spacing={1}>
                                <InputLabel >Rate </InputLabel>
                                <OutlinedInput
                                    id="rate"
                                    type="text"
                                    value={payload.rate ? payload.rate : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "rate",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="rate"
                                    placeholder="Enter Genre Rate"
                                />
                                <ValidationMessage field={"rate"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Stack spacing={1}>
                                <InputLabel >Color </InputLabel>
                                <OutlinedInput
                                    id="color"
                                    type="color"
                                    value={payload.color ? payload.color : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "color",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="color"
                                    placeholder="Choose Color"
                                />
                                <ValidationMessage field={"color"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Stack spacing={1}>
                                <InputLabel >Auto Rate </InputLabel>
                                <Select
                                    id="auto_rate"
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "auto_rate",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )}
                                    value={payload.auto_rate ? payload.auto_rate : "DISABLE"}
                                    name="auto_rate"
                                >
                                    <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                                    <MenuItem value="DISABLE"> DISABLE </MenuItem>
                                </Select>
                                <ValidationMessage field={"auto_rate"} />
                            </Stack>
                        </Grid>

                        <FormMainAction
                            cancel="Cancle"
                            cancelClick={() => navigate(paths.genre)}
                            submit="Update"
                            submitClick={submitGenre}
                            loading={loading}
                        />

                    </Grid>

                </Paper>
            </div>
        </>
    );
};
