export const singerSliderPayload = {
    update: {
        song_id: "",
        singer_id: "",
        color: "",
        sologram: "",
        status: ""
    },
    store: {
        song_id: "",
        singer_id: "",
        color: "",
        sologram: ""
    },
    columns: [
        { id: "sologram", label: "id", minWidth : 170 },
        { id: "color", label: "Color", minWidth: 170 },
        { id: "singer", label: "Singer", minWidth: 170 },
        { id: "song", label: "Song", minWidth: 170 },
        { id: "status", label: "Status", minWidth :170 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "id",
        search: "",
        order: "id",
        sort: "DESC",
        filter: "status",
        value: "",
        start_date: '',
        end_date: ''
    }
}