export const sliderPayload = {
    update: {
        id: "",
        title: "",
        status: "",
        image: ""
    },
    store: {
        title: "",
        status: '',
        image: "",
    },
    columns: [
        { id: "title", label: "Title", minWidth : 170 },
        { id: "image", label: "Image", minWidth : 170 },
        { id: "status", label: "Status", minWidth :170 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "id,title,status",
        search: "",
        order: "id",
        sort: "DESC",
        filter: "status",
        value: "",
        start_date: '',
        end_date: ''
    }
}