import { Grid, InputLabel, OutlinedInput, Stack, Paper, Select, MenuItem} from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../userService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { userPayload } from "../userPayload";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { formatDate } from '../../../shares/FormatDate';


export const UserUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(userPayload.update);
  const { user } = useSelector(state => state.user);
  const params = useParams();
  // const { translate } = useSelector(state => state.setting);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitUser = async () => {
    setLoading(true);

    const formData = formBuilder(payload, userPayload.update);

    await userService.update(dispatch, params.id, formData);

    setLoading(false);
}

  const loadingData = useCallback(async () => {
    setLoading(true);
    
    await userService.show(dispatch, params.id);
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
      loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (user) {
        setPayload(user);
    }
  }, [user])

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

            <Grid container spacing={3}>

              {/* <Grid item xs={12} md={12}>
                <Stack spacing={1} justifyContent="center" alignItems="center" >
                  <Profile
                    payload={payload}
                    setPayload={setPayload}
                    // src={`${endpoints.image}/${payload.image.image}`}
                    field={'profile'}
                  />
                  <ValidationMessage field={"profile"} />
                </Stack>
              </Grid> */}

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Name (required)</InputLabel>
                  <OutlinedInput
                    id="name"
                    type="text"
                    value={payload.name ? payload.name : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "name",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="name"
                    placeholder="Enter Your Name"
                  />
                  <ValidationMessage field={"name"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Email (required)</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    value={payload.email ? payload.email : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "email",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <ValidationMessage field={"email"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Phone (required)</InputLabel>
                  <OutlinedInput
                    id="phone"
                    type="text"
                    value={payload.phone ? payload.phone : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "phone",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="phone"
                    placeholder="Enter Your Phone"
                  />
                  <ValidationMessage field={"phone"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                    <InputLabel htmlFor="table-name">
                        Date of Birth
                    </InputLabel>

                    <OutlinedInput
                        type="date"
                        value={payload.dob ? formatDate(payload.dob) : ""}
                        onChange={(e) =>
                            payloadHandler(
                                payload,
                                e.target.value,
                                "dob",
                                (updateValue) => {
                                    setPayload(updateValue);
                                }
                            )
                        }
                        name="dob"
                        placeholder="Enter Date of Birth"
                    />
                    <ValidationMessage field={"dob"} />
                </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                  <InputLabel > Status </InputLabel>
                  <Select
                  id="status"
                  onChange={(e) =>
                      payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                          setPayload(updateValue);
                      }
                      )}
                  value={payload.status ? payload.status : ""}
                  name="status"
                  >
                  <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                  <MenuItem value="PENDING"> PENDING </MenuItem>
                  </Select>
                  <ValidationMessage field={"status"} />
                </Stack>
              </Grid>


              <FormMainAction
                cancel="Cancle"
                cancelClick={() => navigate(paths.user)}
                submit="Update"
                submitClick={submitUser}
                loading={loading}
              />

            </Grid>

        </Paper>

      </div>
    </>
  );
};
