import { createSlice } from "@reduxjs/toolkit";
import { singerSliderPayload } from "./singerSliderPayload";

const sliderSlice = createSlice({
    name: 'singerSlider',
    initialState: {
        singerSliders: [],
        singerSlider: null,
        paginateParams : singerSliderPayload.paginateParams,
        total : 0
    },
    reducers: {
        index: (state, action) => {
            state.singerSliders = action.payload;
            return state;
        },
        update: (state, action) => {
            state.singerSlider = action.payload;
            return state;
        },
        setPaginate: (state, action) => {
            state.paginateParams = action.payload;
            return state;
        }
    }
});

export const { index, update, setPaginate } = sliderSlice.actions;
export default sliderSlice.reducer;