import { Grid, InputLabel, OutlinedInput, Stack, Paper } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { eventSliderService } from "../eventSliderService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { eventSliderPayload } from '../eventSliderPayload';
import { Profile } from '../../../shares/Profile';

export const EventSliderCreate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(eventSliderPayload.store);
  const [preview, setPreview] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitEventSlider = async () => {
    setLoading(true);
    const formData = formBuilder(payload, eventSliderPayload.store);
    await eventSliderService.store(formData, dispatch);
    setLoading(false);
    navigate(paths.eventSlider)
  };

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid item xs={12} md={12}>
            <Stack spacing={1} justifyContent="center" alignItems="center" >
              <Profile
                preview={preview}
                onSelect={(e) => {
                  payloadHandler(payload, e, 'cover_photo', (updateValue) => {
                    setPayload(updateValue);
                  })
                  setPreview(e);
                }}
              />
              <ValidationMessage field={"cover_photo"} />
            </Stack>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Name (required) </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "name",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Name"
                />
                <ValidationMessage field={"name"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Location </InputLabel>
                <OutlinedInput
                  id="location"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "location",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="location"
                  placeholder="Location"
                />
                <ValidationMessage field={"location"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Address </InputLabel>
                <OutlinedInput
                  id="address"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "address",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="address"
                  placeholder="Address"
                />
                <ValidationMessage field={"address"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Date </InputLabel>
                <OutlinedInput
                  id="date"
                  type="date"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "date",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="date"
                  placeholder="Date"
                />
                <ValidationMessage field={"date"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Time </InputLabel>
                <OutlinedInput
                  id="time"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "time",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="time"
                  placeholder="Time"
                />
                <ValidationMessage field={"time"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Promotion </InputLabel>
                <OutlinedInput
                  id="promotion"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "promotion",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="promotion"
                  placeholder="Promotion"
                />
                <ValidationMessage field={"promotion"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <InputLabel > Artist LineUp </InputLabel>
                <OutlinedInput
                  id="artist_lineup"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "artist_lineup",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="artist_lineup"
                  placeholder="Artist lineup"
                />
                <ValidationMessage field={"artist_lineup"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.eventSlider)}
              submit="Create"
              submitClick={submitEventSlider}
              loading={loading}
            />
          </Grid>
        </Paper>
      </div>
    </>
  );
};