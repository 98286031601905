import { paths } from "../../constants/paths"
import { SingerSliderCreate } from "./entry/singerSliderCreate"
import { SingerSliderUpdate } from "./entry/singerSliderUpdate"

import { SingerSliderList } from "./list/singerSliderList"


export const singerSliderRoutes = [
    {
        id: "singerSlider",
        path: paths.singerSlider,
        element : <SingerSliderList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Singer Slider", url: paths.singerSlider },
                    { label: null, url: null, current : "List" },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "singerSliderCreate",
        path: paths.singerSliderCreate,
        element : <SingerSliderCreate />,
        loader: () => {
            return{
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Singer Slider", url: paths.singerSlider },
                    { label: null, url: null, current : "Create" },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "singerSliderDetail",
        path: `/${paths.singerSlider}/:id`,
        element: <SingerSliderUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Singer Slider", url: paths.singerSlider },
                    { label: null, url: null, current : "Update" },
                ]
            }
        }
    }
]