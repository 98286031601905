import { Grid, InputLabel, OutlinedInput, Stack, Paper, Select, MenuItem } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { episodeService } from "../episodeService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import FormMainAction from "../../../shares/FormMainAction";
import { episodePayload } from '../episodePayload';
import { getRequest } from '../../../helpers/api';
import { endpoints } from '../../../constants/endpoints';

export const EpisodeUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(episodePayload.store);
  const [singers, setSinger] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const submitEpisodeUpdate = async () => {
    setLoading(true);
    await episodeService.update(dispatch, params.id, payload);
    setLoading(false);
    navigate(paths.episode)
  };

  const loadingData = useCallback(async () => {
    setLoading(true);
    const result = await getRequest(`${endpoints.singer}?status=ACTIVE`);

    if (result.status === 200) {
      setSinger(result.data);
    }

    const episodeResult = await getRequest(`${endpoints.episode}/${params.id}`);

    if (episodeResult.status === 200 && payload) {
      const updatePayload = {...payload};
      updatePayload.singer_id = episodeResult.data.singer_id;
      updatePayload.status = episodeResult.data.status;
      updatePayload.url = episodeResult.data.url;
      updatePayload.title = episodeResult.data.title;

      setPayload(updatePayload);
    }
    setLoading(false);
  }, [params.id]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        {payload && (
          <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                  <InputLabel > Title (required) </InputLabel>
                  <OutlinedInput
                    id="title"
                    type="text"
                    value={payload.title ? payload.title : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "title",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="title"
                    placeholder="Episode title"
                  />
                  <ValidationMessage field={"title"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                  <InputLabel > Epsiode URL (required) </InputLabel>
                  <OutlinedInput
                    id="url"
                    type="text"
                    value={payload.url ? payload.url : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "url",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="url"
                    placeholder="URL"
                  />
                  <ValidationMessage field={"url"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                  <InputLabel > Choose Singer (required) </InputLabel>
                  <Select
                    id="singer_id"
                    value={payload.singer_id ? payload.singer_id : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "singer_id",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )}
                    name="status"
                  >
                    {singers && singers.length > 0 && singers.map((value, index) => {
                      return (
                        <MenuItem key={`episode_singer_id_${index}`} value={value.id}> {value.name} </MenuItem>
                      )
                    })}

                  </Select>
                  <ValidationMessage field={"singer_id"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                  <InputLabel > Status </InputLabel>
                  <Select
                    id="status"
                    value={payload.status ? payload.status : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "status",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )}
                    name="status"
                  >
                    <MenuItem value={"ENABLE"}> ENABLE </MenuItem>
                    <MenuItem value={"DISABLE"}> DISABLE </MenuItem>
                  </Select>
                  <ValidationMessage field={"status"} />
                </Stack>
              </Grid>

              <FormMainAction
                cancel="Cancle"
                cancelClick={() => navigate(paths.episode)}
                submit="Update"
                submitClick={submitEpisodeUpdate}
                loading={loading}
              />
            </Grid>
          </Paper>
        )}
      </div>
    </>
  );
};