import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Paper,
  TextField,
  MenuItem,
  Select
} from '@mui/material';
import { paths } from "../../../constants/paths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sliderService } from "../sliderService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { tooltipOptions } from "../../../constants/config";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { sliderPayload } from "../sliderPayload";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ProfileImage } from '../../../shares/ProfileImage';

export const SliderCreate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(sliderPayload.store);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const submitSlider = async () => {
    setLoading(true);
    const formData = formBuilder(payload, sliderPayload.store);

    await sliderService.store(formData, dispatch);

    setLoading(false);
  };

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={1} justifyContent="center" alignItems="center" >
                  <ProfileImage
                    preview={payload.image ? payload.image.image : null}
                    onSelect={(e) => payloadHandler(payload, e, 'image', (updateValue) => {
                        setPayload(updateValue);
                    })}
                  />
                  <ValidationMessage field={"image"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Title (required)</InputLabel>
                  <OutlinedInput
                    id="title"
                    type="text"
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "title",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="title"
                    placeholder="Enter Slider Title"
                  />
                  <ValidationMessage field={"title"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                <InputLabel >Status</InputLabel>
                  <Select
                    id="status"
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "status",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )}
                    name="status"
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="DISSABLE">Dissable</MenuItem>
                  </Select>
                  <ValidationMessage field={"status"} />
                </Stack>
                </Grid>

              <FormMainAction
                cancel="Cancle"
                cancelClick={() => navigate(paths.slider)}
                submit="Create"
                submitClick={submitSlider}
                loading={loading}
              />

            </Grid>

        </Paper>

      </div>
    </>
  );
};
