import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TablePaginationActions, emptyRows } from "../../../constants/config";
import { Box, TableHead, TableSortLabel, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../userService";
import { userPayload } from "../userPayload";
import { setPaginate } from "../userSlice";
import { TableSearch } from "../../../shares/TableSearch";
import { NavigateId } from "../../../shares/NavigateId";
import { paths } from "../../../constants/paths";
import { postRequest } from "../../../helpers/api";
import { endpoints } from "../../../constants/endpoints";
import { ExportExcel } from "../../../shares/Export";
import moment from "moment";
import dayjs from "dayjs";

export const UserList = () => {
    const { users, paginateParams } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [filter, setFilter] = useState({
        start_date: null,
        end_date: null
    })
    const [total, setTotal] = useState(0);
    const [columnIds, setColumnIds] = useState("");
    const [sort, setSort] = useState(true);

    /**
     * Event - Paginate Page Change
     * @param {*} event
     */
    const onPageChange = (event, newPage) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: newPage,
            })
        );
    };

    /**
     * Event - Paginate Row Per Page Change
     * @param {*} event
     */
    const onRowPerPageChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: 1,
                per_page: parseInt(event.target.value, 10),
            })
        );
    };

    /**
     * Event - Sorting
     * @param {*} event
     */
    const onHandleSort = (event, label) => {
        setSort(!sort);
        dispatch(
            setPaginate({
                ...paginateParams,
                sort: sort ? "ASC" : "DESC",
                order: label?.toLowerCase(),
            })
        );
    };

    const ColumnSortHandle = (id) => {
        if (columnIds === id) {
            return sort ? "asc" : "desc";
        }
    };

    /**
     * Event - Search
     * @param {*} event
     */
    const onSearchChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                search: event,
            })
        );
    };

    const loadingData = useCallback(async () => {
        const result = await userService.index(dispatch, paginateParams);
        if (result.status === 200) {
            setTotal(
                result?.data?.total ? result.data.total : result.data.length
            );
        }
    }, [dispatch, paginateParams]);


    useEffect(() => {
        loadingData();
    }, [loadingData]);

    return (
        <div>
            <Breadcrumb />

            <Paper
                sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}
            >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table sx={{ minWidth: 500 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-start"}
                                        marginTop={1}
                                    >

                                        <TableSearch
                                            paginateParams={paginateParams}
                                            onSearchChange={onSearchChange}
                                        />

                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            gap={2}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dayjs(filter.start_date)}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    onChange={(newValue) => {
                                                        const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                        setFilter({ ...filter, start_date: formattedDate });
                                                    }}
                                                    label="Select start date" />
                                            </LocalizationProvider>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dayjs(filter.end_date)}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    onChange={(newValue) => {
                                                        const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                        setFilter({ ...filter, end_date: formattedDate });
                                                    }}
                                                    label="Select end date" />
                                            </LocalizationProvider>

                                            <ExportExcel url={'/user/date/filter'} body={filter} />
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {userPayload.columns.map((column) => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            <TableSortLabel
                                                active={true}
                                                direction={ColumnSortHandle(column.id)}
                                                onClick={(e) => {
                                                    onHandleSort(e, column.id);
                                                    setColumnIds(column.id);
                                                }}
                                            >
                                                <Typography style={{ fontWeight: "600" }}> {column.label} </Typography>
                                            </TableSortLabel>
                                        </TableCell>
                                    );

                                })}
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {userPayload.columns.map((column) => {
                                        const value = row[column.id];
                                        const switchCase = ({ column, value }) => {
                                            switch (column.id) {
                                                case "name":
                                                    return (
                                                        <NavigateId
                                                            url={`${paths.user}/${row.id}`}
                                                            value={value}
                                                        />
                                                    );
                                                case "dob":
                                                    return (
                                                        <span> {moment(value).format("DD-MM-YYYY")} </span>
                                                    )
                                                default:
                                                    return value;
                                            }
                                        };

                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {switchCase({ column, value })}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                            {emptyRows(paginateParams.page, paginateParams.rowsPerPage, users) > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={userPayload.columns.length} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    sx={{
                        width: "100%",
                    }}
                >
                    <TableRow>
                        <TableCell>
                            <TablePagination
                                sx={{
                                    width: "100%",
                                }}
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={total}
                                rowsPerPage={paginateParams.per_page}
                                page={paginateParams ? paginateParams.page - 1 : 0}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowPerPageChange}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableCell>
                    </TableRow>
                </Box>
            </Paper>
        </div>
    );
};
