import { configureStore } from "@reduxjs/toolkit";
import countSlice from "./shares/countSlice";
import shareSlice from "./shares/shareSlice";
import userSlice from "./modules/user/userSlice";
import adminSlice from "./modules/admin/adminSlice";
import singerSlice from "./modules/singer/singerSlice";
import songSlice from "./modules/song/songSlice";
import genreSlice from "./modules/genre/genreSlice";
import outletSlice from "./modules/outlet/outletSlice";
import sliderSlice from "./modules/slider/sliderSlice";
import singerSliderSlice from "./modules/singerSlider/singerSliderSlice";
import eventSliderSlice from "./modules/eventSlider/eventSliderSlice";
import dashboardSlice from "./modules/dashboard/dashboardSlice";
import episodeSlice from "./modules/episode/episodeSlice";

export const stores = configureStore({
    reducer: {
        share: shareSlice,
        count: countSlice,
        user: userSlice,
        admin: adminSlice,
        singer: singerSlice,
        song: songSlice,
        genre: genreSlice,
        outlet: outletSlice,
        slider: sliderSlice,
        singerSlider: singerSliderSlice,
        eventSlider: eventSliderSlice,
        dashboard: dashboardSlice,
        episode: episodeSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
