import { Grid, InputLabel, OutlinedInput, Stack, Paper, MenuItem, Select } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { eventSliderService } from "../eventSliderService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { formBuilder } from '../../../helpers/formBuilder';
import { eventSliderPayload } from '../eventSliderPayload';
import { Profile } from '../../../shares/Profile';

export const EventSliderUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(eventSliderPayload.update);
  const { eventSlider } = useSelector(state => state.eventSlider);
  const [preview, setPreview] = useState(null);
  
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitEventSlider = async () => {
    setLoading(true);
    const formData = formBuilder(payload, eventSliderPayload.update);
    await eventSliderService.update(dispatch, params.id, formData);
    setLoading(false);
    navigate(paths.eventSlider)
  }

  const loadingData = useCallback(async () => {
    setLoading(true);
    await eventSliderService.show(dispatch, params.id);
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (eventSlider) {
      const updatePayload = {...eventSlider}
      updatePayload.cover_photo = null;
      setPreview(eventSlider.cover_photo.image);
      setPayload(updatePayload);
    }
  }, [eventSlider])

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>

          <Grid item xs={12} md={12}>
            <Stack spacing={1} justifyContent="center" alignItems="center" >
              <Profile
                preview={preview}
                onSelect={(e) => {
                  payloadHandler(payload, e, 'cover_photo', (updateValue) => {
                    setPayload(updateValue);
                  })
                  setPreview(e);
                }}
              />
              <ValidationMessage field={"cover_photo"} />
            </Stack>
          </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >  Name </InputLabel>
                <OutlinedInput
                  id="name"
                  type="text"
                  value={payload.name}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "name",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="name"
                  placeholder="Enter name"
                />
                <ValidationMessage field={"name"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Location </InputLabel>
                <OutlinedInput
                  id="location"
                  type="text"
                  value={payload.location}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "location",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="location"
                  placeholder="Location"
                />
                <ValidationMessage field={"location"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Address </InputLabel>
                <OutlinedInput
                  id="address"
                  type="text"
                  value={payload.address}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "address",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="address"
                  placeholder="Address"
                />
                <ValidationMessage field={"address"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Date </InputLabel>
                <OutlinedInput
                  id="date"
                  type="date"
                  value={payload.date}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "date",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="date"
                  placeholder="Date"
                />
                <ValidationMessage field={"date"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Time </InputLabel>
                <OutlinedInput
                  id="time"
                  type="text"
                  value={payload.time}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "time",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="time"
                  placeholder="Time"
                />
                <ValidationMessage field={"time"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Promotion </InputLabel>
                <OutlinedInput
                  id="promotion"
                  type="text"
                  value={payload.promtion}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "promotion",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="promotion"
                  placeholder="Promotion"
                />
                <ValidationMessage field={"promotion"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <InputLabel > Artist Lineup </InputLabel>
                <OutlinedInput
                  id="artist_lineup"
                  type="text"
                  value={payload.artist_lineup}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "artist_lineup",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="artist_lineup"
                  placeholder="Artist Lineup"
                />
                <ValidationMessage field={"artist_lineup"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Status</InputLabel>
                <Select
                  id="status"
                  value={payload.status}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="status"
                >
                  <MenuItem value={"ACTIVE"}> ACTIVE </MenuItem>
                  <MenuItem value={"DISABLE"}> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"status"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.eventSlider)}
              submit="Create"
              submitClick={submitEventSlider}
              loading={loading}
            />
          </Grid>
        </Paper>
      </div>
    </>
  );
};