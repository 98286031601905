import { paths } from "../../constants/paths"
import { EpisodeCreate } from "./entry/episodeCreate"
import { EpisodeUpdate } from "./entry/episodeUpdate"
import { EpisodeList } from "./list/episodeList"
export const episodeRoutes = [
    {
        id: "episode",
        path: paths.episode,
        element: <EpisodeList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Episode", url: paths.episode },
                    { label: "Create", url: `${paths.episode}/new`},
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "episodeCreate",
        path: `${paths.episode}/new`,
        element: <EpisodeCreate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "List", url: paths.episode},
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "episodeUpdate",
        path: `${paths.episode}/:id`,
        element: <EpisodeUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "List", url: paths.episode},
                    { label: "Create", url: `${paths.episode}/new`},
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    // {
    //     id: "eventSliderUpdate",
    //     path: `/${paths.eventSlider}/:id`,
    //     element: <EventSliderUpdate />,
    //     loader: () => {
    //         return {
    //             breadcrumbs: [
    //                 { label: "Dashboard", url: paths.dashboard },
    //                 { label: "Event Slider", url: paths.eventSlider },
    //                 { label: null, url: null, current: "Update" },
    //             ]
    //         }
    //     }
    // }
]