import { createSlice } from "@reduxjs/toolkit";
import { eventSliderPayload } from "./eventSliderPayload";

const eventSliderSlice = createSlice({
    name: 'eventSlider',
    initialState: {
        eventSliders: [],
        eventSlider: null,
        paginateParams : eventSliderPayload.paginateParams,
        total : 0
    },
    reducers: {
        index: (state, action) => {
            state.eventSliders = action.payload;
            return state;
        },
        update: (state, action) => {
            state.eventSlider = action.payload;
            return state;
        },
        setPaginate: (state, action) => {
            state.paginateParams = action.payload;
            return state;
        }
    }
});

export const { index, update, setPaginate } = eventSliderSlice.actions;
export default eventSliderSlice.reducer;