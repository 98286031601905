import { Grid, InputLabel, OutlinedInput, Stack, Paper, Select, MenuItem } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { episodeService } from "../episodeService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import FormMainAction from "../../../shares/FormMainAction";
import { episodePayload } from '../episodePayload';
import { getRequest } from '../../../helpers/api';

export const EpisodeCreate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(episodePayload.store);
  const [singers, setSinger] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitEventSlider = async () => {
    setLoading(true);
    await episodeService.store(payload, dispatch);
    setLoading(false);
    navigate(paths.episode)
  };

  const loadingData = useCallback(async () => {
    setLoading(true);
    const result = await getRequest(`${paths.singer}?status=ACTIVE`);

    if(result.status === 200) {
      setSinger(result.data);
    }
    
    setLoading(false);
  }, []);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Title (required) </InputLabel>
                <OutlinedInput
                  id="title"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "title",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="title"
                  placeholder="Episode title"
                />
                <ValidationMessage field={"title"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Epsiode URL (required) </InputLabel>
                <OutlinedInput
                  id="url"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "url",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="url"
                  placeholder="URL"
                />
                <ValidationMessage field={"url"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Choose Singer (required) </InputLabel>
                <Select
                  id="singer_id"
                  value={payload.singer_id}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "singer_id",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="status"
                >
                  {singers && singers.length > 0 && singers.map((value, index) => {
                    return (
                      <MenuItem key={`episode_singer_id_${index}`} value={value.id}> {value.name} </MenuItem>
                    )
                  })}

                </Select>
                <ValidationMessage field={"singer_id"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <InputLabel > Status </InputLabel>
                <Select
                  id="status"
                  value={payload.status}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="status"
                >
                  <MenuItem value={"ENABLE"}> ENABLE </MenuItem>
                  <MenuItem value={"DISABLE"}> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"status"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.episode)}
              submit="Create"
              submitClick={submitEventSlider}
              loading={loading}
            />
          </Grid>
        </Paper>
      </div>
    </>
  );
};