import { createBrowserRouter } from "react-router-dom";
import { DefaultLayout } from "./layouts/default";
import { NotFound } from "./layouts/default/pages/NotFound";
import { BlankTemplate } from "./layouts/default/pages/BlankTemplate";
import { Login } from "./modules/auth/entry/Login";
import { dashboardRoutes } from "./modules/dashboard/dashboardRoute";
import { userRoutes } from "./modules/user/userRoutes";
import { adminRoutes } from "./modules/admin/adminRoutes";
import { singerRoutes } from "./modules/singer/singerRoutes";
import { songRoutes } from "./modules/song/songRoutes";
import { genreRoutes } from "./modules/genre/genreRoutes";
import { outletRoutes } from "./modules/outlet/outletRoutes";
import { sliderRoutes } from "./modules/slider/sliderRoutes";
import { singerSliderRoutes } from "./modules/singerSlider/singerSliderRoutes";
import { eventSliderRoutes } from "./modules/eventSlider/eventSliderRoutes";
import { episodeRoutes } from "./modules/episode/episodeRoutes";

export const routers = createBrowserRouter([
    {
        path: "/",
        element: <DefaultLayout />,
        errorElement: <NotFound />,
        children: [
            ...dashboardRoutes,
            ...userRoutes,
            ...adminRoutes,
            ...singerRoutes,
            ...songRoutes,
            ...genreRoutes,
            ...outletRoutes,
            ...sliderRoutes,
            ...singerSliderRoutes,
            ...eventSliderRoutes,
            ...episodeRoutes
        ],
    },
    {
        path: "auth",
        element: <BlankTemplate />,
        errorElement: <NotFound />,
        children: [
            {
                path: "login",
                element: <Login />,
            },
        ],
    },
]);
