import { paths } from "../../constants/paths";
import { GenreCreate } from "./entry/GenreCreate";
import { GenreUpdate } from "./entry/GenreUpdate";
import { GenreList } from "./list/GenreList";

export const genreRoutes = [
    {
        id: "genre",
        path: paths.genre,
        element: <GenreList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Genre", url: paths.genre },
                    { label: "Create", url: `${paths.genre}/new`},
                ],
                role: ["ADMINISTRATOR"],
            };
        },
    },
    {
        id: "genreCreate",
        path: paths.genreCreate,
        element: <GenreCreate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Genre", url: paths.genre },
                ],
                role: ["ADMINISTRATOR"],
            };
        },
    },
    {
        id: "genreDetail",
        path: `/${paths.genre}/:id`,
        element: <GenreUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Genre", url: paths.genre },
                    { label: "Create", url: `${paths.genre}/new`},
                ]
            }
        }
    }
];
