import { Grid, InputLabel, OutlinedInput, Stack, Paper, MenuItem, Select } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singerSliderService } from "../singerSliderService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { singerSliderPayload } from "../singerSliderPayload";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { getRequest } from '../../../helpers/api';
import { endpoints } from '../../../constants/endpoints';

export const SingerSliderUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(singerSliderPayload.update);
  const { singerSlider } = useSelector(state => state.singerSlider);
  const [singers, setSinger] = useState([]);
  const [songs, setSong] = useState([]);
  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitSlider = async () => {
    setLoading(true);
    await singerSliderService.update(dispatch, params.id, payload);
    setLoading(false);
  }

  const loadingData = useCallback(async () => {
    setLoading(true);
    await singerSliderService.show(dispatch, params.id);
    const singerResult = await getRequest(`${endpoints.singer}`);

    if (singerResult.status === 200) {
      setSinger(singerResult.data);
    }

    const songResult = await getRequest(`${endpoints.song}`);

    if (songResult.status === 200) {
      setSong(songResult.data);
    }

    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (singerSlider) {
      setPayload(singerSlider);
    }
  }, [singerSlider])

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >Choose Singer  </InputLabel>
                <Select
                  id="singer_id"
                  value={payload.singer_id}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "singer_id",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="singer_id"
                >
                  {singers.map((value, index) => {
                    return (
                      <MenuItem key={`singer_id${index}`} value={value.id}> {value.name} </MenuItem>
                    )
                  })}
                </Select>
                <ValidationMessage field={"song_id"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >Choose Song </InputLabel>
                <Select
                  id="song_id"
                  value={payload.song_id}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "song_id",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="song_id"
                >
                  {songs.map((value, index) => {
                    return (
                      <MenuItem key={`song_id_${index}`} value={value.id}> {value.name} </MenuItem>
                    )
                  })}
                </Select>
                <ValidationMessage field={"song_id"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Sologram</InputLabel>
                <OutlinedInput
                  id="sologram"
                  type="text"
                  value={payload.sologram}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "sologram",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="sologram"
                  placeholder="Enter Singer Slider sologram"
                />
                <ValidationMessage field={"sologram"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Color </InputLabel>
                <OutlinedInput
                  id="color"
                  type="color"
                  value={payload.color}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "color",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="color"
                  placeholder="Choose Color"
                />
                <ValidationMessage field={"color"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Status</InputLabel>
                <Select
                  id="status"
                  value={payload.status}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "status",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="status"
                >
                  <MenuItem value={"ACTIVE"}> ACTIVE </MenuItem>
                  <MenuItem value={"DISABLE"}> DISABLE </MenuItem>
                </Select>
                <ValidationMessage field={"status"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.singerSlider)}
              submit="Create"
              submitClick={submitSlider}
              loading={loading}
            />

          </Grid>

        </Paper>

      </div>
    </>
  );
};