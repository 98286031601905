// import React, { useCallback, useEffect, useRef, useState } from "react";
// import { Breadcrumb } from "../../../shares/Breadcrumbs";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import { TablePaginationActions, emptyRows } from "../../../constants/config";
// import { Avatar, Box, TableHead, TableSortLabel } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { singerSliderService } from "../singerSliderService";
// import { singerSliderPayload } from "../singerSliderPayload";
// import { setPaginate } from "../singerSliderSlice";
// import { endpoints } from "../../../constants/endpoints";
// import { TableSearch } from "../../../shares/TableSearch";
// import { NavigateId } from "../../../shares/NavigateId";
// import { paths } from "../../../constants/paths";

// export const SingerSliderList = () => {
//     const { singerSliders, paginateParams } = useSelector((state) => state.singerSlider);
//     const dispatch = useDispatch();

//     const [total, setTotal] = useState(0);
//     const [columnIds, setColumnIds] = useState("");
//     const [sort, setSort] = useState(true);
//     const singerSongSliderColumns = useRef(singerSliderPayload.columns);

//     const onPageChange = (event, newPage) => {
//         dispatch(
//             setPaginate({
//                 ...paginateParams,
//                 page: newPage,
//             })
//         );
//     };

//     const onRowPerPageChange = (event) => {
//         dispatch(
//             setPaginate({
//                 ...paginateParams,
//                 page: 1,
//                 per_page: parseInt(event.target.value, 10),
//             })
//         );
//     };

//     const onHandleSort = (event, label) => {
//         setSort(!sort);
//         dispatch(
//             setPaginate({
//                 ...paginateParams,
//                 sort: sort ? "ASC" : "DESC",
//                 order: label?.toLowerCase(),
//             })
//         );
//     };

//     const ColumnSortHandle = (id) => {
//         if (columnIds === id) {
//             return sort ? "asc" : "desc";
//         }
//     };

//     const onSearchChange = (event) => {
//         dispatch(
//             setPaginate({
//                 ...paginateParams,
//                 search: event,
//             })
//         );
//     };

//     const loadingData = useCallback(async () => {
//         const result = await singerSliderService.index(dispatch, paginateParams);
//         if (result.status === 200) {
//             setTotal(
//                 result?.data?.total ? result.data.total : result.data.length
//             );
//         }
//     }, [dispatch, paginateParams]);

//     useEffect(() => {
//         loadingData();
//     }, [loadingData]);

//     // const IconRender = ({ dataSource }) => {
//     //     return (
//     //         <Avatar alt="icon" src={dataSource ? `${endpoints.image}/${dataSource.image}` : null} />
//     //     )
//     // }

//     return (
//         <div>
//             <Breadcrumb />

//             <Paper
//                 sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}
//             >
//                 <TableContainer sx={{ maxHeight: 540 }}>
//                     <Table sx={{ minWidth: 500 }}>
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell colSpan={12}>
//                                     <Box
//                                         display={"flex"}
//                                         alignItems={"center"}
//                                         justifyContent={"space-between"}
//                                         marginTop={1}
//                                     >
//                                         <Box
//                                             display={"flex"}
//                                             alignItems={"center"}
//                                             justifyContent={"space-between"}
//                                             gap={2}
//                                         ></Box>

//                                         <TableSearch
//                                             paginateParams={paginateParams}
//                                             onSearchChange={onSearchChange}
//                                         />
//                                     </Box>
//                                 </TableCell>
//                             </TableRow>
//                             <TableRow>
//                                 {singerSongSliderColumns.current.map((column) => (
//                                     <TableCell
//                                         key={column.id}
//                                         align={column.align}
//                                         style={{ minWidth: column.minWidth }}
//                                     >
//                                         <TableSortLabel
//                                             active={true}
//                                             direction={ColumnSortHandle(
//                                                 column.id
//                                             )}
//                                             onClick={(e) => {
//                                                 onHandleSort(e, column.id);
//                                                 setColumnIds(column.id);
//                                             }}
//                                         >
//                                             {column.label}
//                                         </TableSortLabel>
//                                     </TableCell>
//                                 ))}
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {singerSliders?.map((row) => {
//                                 return (
//                                     <TableRow
//                                         hover
//                                         role="checkbox"
//                                         tabIndex={-1}
//                                         key={row.id}
//                                     >
//                                         {singerSongSliderColumns?.current.map((column) => {

//                                             const value = row[column.id];

//                                             const switchCase = ({ column, value }) => {
//                                                 switch (column) {
//                                                     case "id":
//                                                         return <NavigateId
//                                                             url={`${paths.genre}/${row.id}`}
//                                                             value={value}
//                                                         />
//                                                     // case "icon":
//                                                     //     return <IconRender dataSource={value} />;

//                                                     default:
//                                                         return value;
//                                                 }
//                                             }

//                                             return (
//                                                 <TableCell
//                                                     key={column.id}
//                                                     align={column.align}
//                                                 >
//                                                     {switchCase({ column: column.id, value: value })}
//                                                 </TableCell>
//                                             );
//                                         })}
//                                     </TableRow>
//                                 );
//                             })}
//                             {emptyRows(
//                                 paginateParams.page,
//                                 paginateParams.rowsPerPage,
//                                 singerSliders
//                             ) > 0 && (
//                                     <TableRow style={{ height: 53 * emptyRows }}>
//                                         <TableCell colSpan={6} />
//                                     </TableRow>
//                                 )}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <Box
//                     display={"flex"}
//                     alignItems={"center"}
//                     justifyContent={"right"}
//                     sx={{
//                         width: "100%",
//                     }}
//                 >
//                     <TableRow>
//                         <TableCell>
//                             <TablePagination
//                                 sx={{
//                                     width: "100%",
//                                 }}
//                                 rowsPerPageOptions={[1, 10, 25]}
//                                 colSpan={3}
//                                 count={total}
//                                 rowsPerPage={paginateParams.per_page}
//                                 page={paginateParams ? paginateParams.page - 1 : 0}
//                                 SelectProps={{
//                                     inputProps: {
//                                         "aria-label": "rows per page",
//                                     },
//                                     native: true,
//                                 }}
//                                 onPageChange={onPageChange}
//                                 onRowsPerPageChange={onRowPerPageChange}
//                                 ActionsComponent={TablePaginationActions}
//                             />
//                         </TableCell>
//                     </TableRow>
//                 </Box>
//             </Paper>
//         </div>
//     );
// };

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePaginationActions, emptyRows } from "../../../constants/config";
import { Avatar, Box, TableHead, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPaginate } from "../singerSliderSlice";
import { TableSearch } from "../../../shares/TableSearch";
import { endpoints } from "../../../constants/endpoints";
import { paths } from "../../../constants/paths";
import { NavigateId } from "../../../shares/NavigateId";
import { singerSliderPayload } from "../singerSliderPayload";
import { singerSliderService } from "../singerSliderService";

export const SingerSliderList = () => {
    const { singerSliders, paginateParams } = useSelector((state) => state.singerSlider);
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [columnIds, setColumnIds] = useState("");
    const [sort, setSort] = useState(true);
    const genreColumns = useRef(singerSliderPayload.columns);

    /**
     * Event - Paginate Page Change
     * @param {*} event
     */
    const onPageChange = (event, newPage) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: newPage,
            })
        );
    };

    /**
     * Event - Paginate Row Per Page Change
     * @param {*} event
     */
    const onRowPerPageChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                page: 1,
                per_page: parseInt(event.target.value, 10),
            })
        );
    };

    /**
     * Event - Sorting
     * @param {*} event
     */
    const onHandleSort = (event, label) => {
        setSort(!sort);
        dispatch(
            setPaginate({
                ...paginateParams,
                sort: sort ? "ASC" : "DESC",
                order: label?.toLowerCase(),
            })
        );
    };

    const ColumnSortHandle = (id) => {
        if (columnIds === id) {
            return sort ? "asc" : "desc";
        }
    };

    /**
     * Event - Search
     * @param {*} event
     */
    const onSearchChange = (event) => {
        dispatch(
            setPaginate({
                ...paginateParams,
                search: event,
            })
        );
    };

    const loadingData = useCallback(async () => {
        const result = await singerSliderService.index(dispatch, paginateParams);
        if (result.status === 200) {
            setTotal(
                result?.data?.total ? result.data.total : result.data.length
            );
        }
    }, [dispatch, paginateParams]);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    const IconRender = ({ dataSource }) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: "bold", paddingBottom: "5px"}}> {dataSource ? dataSource.name : "" } </label>
            <Avatar alt="icon" src={dataSource ? `${endpoints.image}/${dataSource.profile.image}` : ""} />
            </div>
        )
    }


    return (
        <div>
            <Breadcrumb />

            <Paper
                sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}
            >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table sx={{ minWidth: 500 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        marginTop={1}
                                    >
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            gap={2}
                                        ></Box>

                                        <TableSearch
                                            paginateParams={paginateParams}
                                            onSearchChange={onSearchChange}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {genreColumns.current.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <TableSortLabel
                                            active={true}
                                            direction={ColumnSortHandle(
                                                column.id
                                            )}
                                            onClick={(e) => {
                                                onHandleSort(e, column.id);
                                                setColumnIds(column.id);
                                            }}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {singerSliders?.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        {genreColumns?.current.map((column) => {

                                            const value = row[column.id];
                                            const switchCase = ({ column, value }) => {
                                                switch (column) {
                                                    case "sologram":
                                                        return <NavigateId
                                                            url={`${paths.singerSlider}/${row.id}`}
                                                            value={value}
                                                        />
                                                    case "color":
                                                        return (
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                <span style={{ background: value, width: "10px", height: "10px", borderRadius: "50%" }}> </span>
                                                                <span style={{ marginLeft: "20px" }}> {value} </span>
                                                            </div>
                                                        )
                                                    case "singer":
                                                        return <IconRender dataSource={value} />;
                                                    case "song":
                                                        return (
                                                            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                                                <label style={{paddingLeft: "20px", fontWeight: "bold"}}> {row.song.name}</label>
                                                                <audio controls>
                                                                    <source src={`${endpoints.audio}/${row.song.file_path}`} type="audio/mpeg"></source>
                                                                </audio>
                                                            </div>
                                                        )
                                                    default:
                                                        return value;
                                                }
                                            }

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {switchCase({ column: column.id, value: value })}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {emptyRows(
                                paginateParams.page,
                                paginateParams.rowsPerPage,
                                singerSliders
                            ) > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    sx={{
                        width: "100%",
                    }}
                >
                    <TableRow>
                        <TableCell>
                            <TablePagination
                                sx={{
                                    width: "100%",
                                }}
                                rowsPerPageOptions={[1, 10, 25]}
                                colSpan={3}
                                count={total}
                                rowsPerPage={paginateParams.per_page}
                                page={paginateParams ? paginateParams.page - 1 : 0}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowPerPageChange}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableCell>
                    </TableRow>
                </Box>
            </Paper>
        </div>
    );
};
