import { Grid, InputLabel, OutlinedInput, Stack, Paper, Select, MenuItem, } from "@mui/material";
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { outletService } from "../outletService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from "../../../shares/Breadcrumbs";
import { outletPayload } from "../outletPayload";
import { Profile } from "../../../shares/Profile";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from "../../../shares/ValidationMessage";

export const OutletUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState(outletPayload.update);
    const [outletImage, setOutletImage] = useState(null);
    const { outlet } = useSelector((state) => state.outlet);
    const params = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitOutlet = async () => {
        setLoading(true);
        const formData = formBuilder(payload, outletPayload.update);
        await outletService.update(dispatch, params.id, formData);
        setLoading(false);
    };

    const loadingData = useCallback(async () => {
        setLoading(true);
        await outletService.show(dispatch, params.id);
        setLoading(false);
    }, [dispatch, params.id]);

    useEffect(() => {
        loadingData();
    }, [loadingData]);

    useEffect(() => {
        if (outlet) {
            const updatePayload = {...outlet};
            updatePayload.image = null;
            setPayload(updatePayload);

            if(outlet.image) {
                setOutletImage(outlet.image.image);
            }
        }
    }, [outlet]);

    return (
        <>
            <div className=" grid">
                <div className="col-12">
                    <Breadcrumb />
                </div>

                <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

                    <Grid item xs={12} md={12}>
                        <Stack spacing={1} justifyContent="center" alignItems="center" >
                            <Profile
                                preview={outletImage}
                                onSelect={(e) => payloadHandler(payload, e, 'image', (updateValue) => {
                                    setPayload(updateValue);
                                })}
                            />
                            <ValidationMessage field={"image"} />
                        </Stack>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Name
                                </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    value={payload.name ? payload.name : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "name",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="name"
                                    placeholder="Enter Your Name"
                                />
                                <ValidationMessage field={"name"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Phone
                                </InputLabel>
                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    value={payload.phone ? payload.phone : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "phone",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                />
                                <ValidationMessage field={"rate"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Address
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    value={
                                        payload.address ? payload.address : ""
                                    }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "address",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="address"
                                    placeholder="Enter Address"
                                />
                                <ValidationMessage field={"address"} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Date
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="date"
                                    value={payload.date ? payload.date : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "date",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="rate"
                                    placeholder="Enter Rate"
                                />
                                <ValidationMessage field={"date"} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Time
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="type"
                                    value={payload.time ? payload.time : ""}
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "time",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="time"
                                    placeholder="Enter Time"
                                />
                                <ValidationMessage field={"time"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Promotion
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    value={
                                        payload.promotion
                                            ? payload.promotion
                                            : ""
                                    }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "promotion",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="promotion"
                                    placeholder="Enter Promotion"
                                />
                                <ValidationMessage field={"promotion"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Promo Description
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="text"
                                    value={
                                        payload.promo_description
                                            ? payload.promo_description
                                            : ""
                                    }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "promo_description",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="promo_description"
                                    placeholder="Enter Description"
                                />
                                <ValidationMessage
                                    field={"promo_description"}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Latitude
                                </InputLabel>

                                <OutlinedInput
                                    id="table-name"
                                    type="number"
                                    value={
                                        payload.latitude ? payload.latitude : ""
                                    }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "latitude",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="latitude"
                                    placeholder="Enter Latitude"
                                />
                                <ValidationMessage field={"latitude"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel >
                                    Longitude
                                </InputLabel>

                                <OutlinedInput
                                    id="longitude"
                                    type="number"
                                    value={ payload.longitude ? payload.longitude : "" }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "longitude",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="longitude"
                                    placeholder="Enter Longitude"
                                />
                                <ValidationMessage field={"longitude"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel >
                                    Branch (required)
                                </InputLabel>

                                <OutlinedInput
                                    type="text"
                                    value={ payload.branch ? payload.branch : "" }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "branch",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="branch"
                                    placeholder="Enter Branch"
                                />
                                <ValidationMessage field={"branch"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel htmlFor="table-name">
                                    Month 
                                </InputLabel>

                                <OutlinedInput
                                    type="text"
                                    value={ payload.month ? payload.month : "" }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "month",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="month"
                                    placeholder="Enter Month"
                                />
                                <ValidationMessage field={"month"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel>
                                    Activation Date 
                                </InputLabel>

                                <OutlinedInput
                                    type="text"
                                    value={ payload.activation_date ? payload.activation_date : "" }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "activation_date",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="activation_date"
                                    placeholder="Enter Activation Date"
                                />
                                <ValidationMessage field={"activation_date"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={1}>
                                <InputLabel >
                                    Music Band 
                                </InputLabel>

                                <OutlinedInput
                                    type="text"
                                    value={ payload.music_band ? payload.music_band : "" }
                                    onChange={(e) =>
                                        payloadHandler(
                                            payload,
                                            e.target.value,
                                            "music_band",
                                            (updateValue) => {
                                                setPayload(updateValue);
                                            }
                                        )
                                    }
                                    name="music_band"
                                    placeholder="Enter Music Band"
                                />
                                <ValidationMessage field={"music_band"} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Stack spacing={1}>
                                <InputLabel > Status </InputLabel>
                                <Select
                                id="status"
                                onChange={(e) =>
                                    payloadHandler(
                                    payload,
                                    e.target.value,
                                    "status",
                                    (updateValue) => {
                                        setPayload(updateValue);
                                    }
                                    )}
                                value={payload.status ? payload.status : "DISABLE"}
                                name="status"
                                >
                                <MenuItem value="ACTIVE"> ACTIVE </MenuItem>
                                <MenuItem value="DISABLE"> DISABLE </MenuItem>
                                </Select>
                                <ValidationMessage field={"status"} />
                            </Stack>
                        </Grid>

                        <FormMainAction
                            cancel="Cancle"
                            cancelClick={() => navigate(paths.outlet)}
                            submit="Update"
                            submitClick={submitOutlet}
                            loading={loading}
                        />
                    </Grid>
                </Paper>
            </div>
        </>
    );
};
