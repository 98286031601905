import { paths } from "../../constants/paths"
import { AdminCreate } from "./entry/AdminCreate"
import { AdminUpdate } from "./entry/AdminUpdate"
import { AdminList } from "./list/AdminList"


export const adminRoutes = [
    {
        id: "admin",
        path: paths.admin,
        element : <AdminList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Admin", url: paths.admin },
                    { label: "Create", url: `${paths.admin}/new` },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "adminCreate",
        path: paths.adminCreate,
        element : <AdminCreate />,
        loader: () => {
            return{
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Admin", url: paths.admin },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "adminDetail",
        path: `/${paths.admin}/:id`,
        element: <AdminUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Admin", url: paths.admin },
                    { label: "Create", url: `${paths.admin}/new` },
                ]
            }
        }
    }
]