import { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import AnalyticEcommerce from '../../../shares/AnalyticEcommerce';
import { dashboardService } from '../dashboardService';
import { useDispatch, useSelector } from 'react-redux';

export const DashboardList = () => {

  const { votes, totaluser } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const loadingData = useCallback(async () => {
    await dashboardService.uservote(dispatch);
    await dashboardService.totaluser(dispatch);

  }, [dispatch]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  return (
    <div>
      <Breadcrumb />
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ mb: -3.65, mt: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">Vote Statics</Typography>
        </Grid>
        {votes && votes.map((vote, index) => {
          return (
            <Grid key={`vote_id_${index}`} item xs={12} sm={6} md={4} lg={3}>
              <AnalyticEcommerce title={vote.genre} count={vote.count} />
            </Grid>
          )
        })}
        
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">Total Statics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce title="Total Page Views" count={totaluser.user} />
        </Grid>

        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
      </Grid>
    </div>
  );
};


