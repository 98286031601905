import { paths } from "../../constants/paths"
import { EventSliderCreate } from "./entry/eventSliderCreate"
import { EventSliderUpdate } from "./entry/eventSliderUpdate"
import { EventSliderList } from "./list/eventSliderList"

export const eventSliderRoutes = [
    {
        id: "eventSlider",
        path: paths.eventSlider,
        element: <EventSliderList />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Events", url: paths.eventSlider },
                    { label: "Create", url: `${paths.eventSlider}/new` },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "eventSliderCreate",
        path: paths.eventSliderCreate,
        element: <EventSliderCreate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Events", url: paths.eventSlider },
                ],
                role: ['ADMINISTRATOR']
            }
        }
    },
    {
        id: "eventSliderUpdate",
        path: `/${paths.eventSlider}/:id`,
        element: <EventSliderUpdate />,
        loader: () => {
            return {
                breadcrumbs: [
                    { label: "Dashboard", url: paths.dashboard },
                    { label: "Events", url: paths.eventSlider },
                    { label: "Create", url: `${paths.eventSlider}/new` },
                ]
            }
        }
    }
]