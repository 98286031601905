export const episodePayload = {
    update: {
        title: "",
        url: "",
        status: "",
        singer_id: ""
    },
    store: {
        title: "",
        url: "",
        status: "ACTIVE",
        singer_id: ""
    },
    columns: [
        { id: "title", label: "Title", minWidth: 170 },
        { id: "url", label: "Url", minWidth: 170 },
        { id: "singer", label: "Singer", minWidth: 170 },
        { id: "status", label: "Status", minWidth :170 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "id,url,title,status",
        search: "",
        order: "id",
        sort: "DESC",
        filter: "status",
        value: "",
        start_date: '',
        end_date: ''
    }
}