export const eventSliderPayload = {
    update: {
        name: "",
        cover_photo: "",
        location: "",
        address: "",
        date: "",
        time: "",
        promotion: "",
        artist_lineup: "",
        status: ""
    },
    store: {
        name: "",
        cover_photo: "",
        location: "",
        address: "",
        date: "",
        time: "",
        promotion: "",
        artist_lineup: ""
    },
    columns: [
        { id: "name", label: "Name", minWidth: 170 },
        { id: "cover_photo", label: "Cover Photo", minWidth: 170 },
        { id: "location", label: "Location", minWidth: 170 },
        { id: "address", label: "Address", minWidth: 170 },
        { id: "date", label: "Date", minWidth: 170 },
        { id: "time", label: "Time", minWidth: 170 },
        { id: "promotion", label: "Promotion", minWidth: 170 },
        { id: "status", label: "Status", minWidth :170 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "id",
        search: "",
        order: "id",
        sort: "DESC",
        filter: "status",
        value: "",
        start_date: '',
        end_date: ''
    }
}