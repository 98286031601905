import { Grid, InputLabel, OutlinedInput, Stack, Paper, MenuItem, Select } from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { singerSliderService } from "../singerSliderService";
import { ValidationMessage } from "../../../shares/ValidationMessage";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { singerSliderPayload } from "../singerSliderPayload";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { getRequest } from '../../../helpers/api';
import { endpoints } from '../../../constants/endpoints';

export const SingerSliderCreate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(singerSliderPayload.store);
  const [singers, setSinger] = useState([]);
  const [songs, setSongs] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const submitSlider = async () => {
    setLoading(true);
    const formData = formBuilder(payload, singerSliderPayload.store);
    await singerSliderService.store(formData, dispatch);
    setLoading(false);
  };

  const loadingData = useCallback(async () => {
    setLoading(true);
    const singersResult = await getRequest(`${endpoints.singer}`);

    if (singersResult.status === 200) {
      setSinger(singersResult.data);
    }

    const songsResult = await getRequest(`${endpoints.song}`);

    if (songsResult.status === 200) {
      setSongs(songsResult.data);
    }

    if(payload.singer_id === "" && payload.song_id === "") {
      let updatePayload = {...payload};
      updatePayload.singer_id = singersResult.data.length > 0 ? singersResult.data[0].id : "";
      updatePayload.song_id = songsResult.data.length > 0 ? songsResult.data[0].id : "";
      setPayload(updatePayload); 
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  useEffect(() => {
  },[payload, singers, songs]);

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >Choose Singer (Required)  </InputLabel>
                <Select
                  id="singer_id"
                  value={payload.singer_id}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "singer_id",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="singer_id"
                >
                  { singers.map((value, index) => {
                    return (
                      <MenuItem key={`singer_id${index}`} value={value.id}> {value.name} </MenuItem>
                    )
                  })}
                </Select>
                <ValidationMessage field={"song_id"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel >Choose Song (Required) </InputLabel>
                <Select
                  id="song_id"
                  value={payload.song_id}
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "song_id",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )}
                  name="song_id"
                >
                  { songs.map((value, index) => {
                    return (
                      <MenuItem key={`song_id_${index}`} value={value.id}> {value.name} </MenuItem>
                    )
                  })}
                </Select>
                <ValidationMessage field={"song_id"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Sologram</InputLabel>
                <OutlinedInput
                  id="sologram"
                  type="text"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "sologram",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="sologram"
                  placeholder="Enter Singer Slider sologram"
                />
                <ValidationMessage field={"sologram"} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel > Color </InputLabel>
                <OutlinedInput
                  id="color"
                  type="color"
                  onChange={(e) =>
                    payloadHandler(
                      payload,
                      e.target.value,
                      "color",
                      (updateValue) => {
                        setPayload(updateValue);
                      }
                    )
                  }
                  name="color"
                  placeholder="Choose Color"
                />
                <ValidationMessage field={"color"} />
              </Stack>
            </Grid>

            <FormMainAction
              cancel="Cancle"
              cancelClick={() => navigate(paths.singerSlider)}
              submit="Create"
              submitClick={submitSlider}
              loading={loading}
            />

          </Grid>

        </Paper>

      </div>
    </>
  );
};