import {
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Paper,
  MenuItem,
  Select
} from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sliderService } from "../sliderService";
import { payloadHandler } from "../../../helpers/handler";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { sliderPayload } from "../sliderPayload";

import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { ProfileImage } from '../../../shares/ProfileImage';

export const SliderUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(sliderPayload.update);
  const { slider } = useSelector(state => state.slider);
  const params = useParams();
  // const { translate } = useSelector(state => state.setting);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitSlider = async () => {
    setLoading(true);

    const formData = formBuilder(payload, sliderPayload.update);

    await sliderService.update(dispatch, params.id, formData);

    setLoading(false);
}

  const loadingData = useCallback(async () => {
    setLoading(true);
    
    await sliderService.show(dispatch, params.id);
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
      loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (slider) {
      console.log(slider)
        setPayload(slider);
    }
  }, [slider])

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={1} justifyContent="center" alignItems="center" >
                  <ProfileImage
                    preview={payload.image ? payload.image.image : null}
                    onSelect={(e) => payloadHandler(payload, e, 'image', (updateValue) => {
                        setPayload(updateValue);
                    })}
                  />
                  <ValidationMessage field={"image"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Title</InputLabel>
                  <OutlinedInput
                    id="title"
                    type="text"
                    value={payload.title ? payload.title : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "title",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="title"
                    placeholder="Enter Slider Title"
                  />
                  <ValidationMessage field={"title"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                <InputLabel >Status</InputLabel>
                  <Select
                    id="status"
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "status",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )}
                    value={payload.status ? payload.status : ""}
                    name="status"
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="DISSABLE">Dissable</MenuItem>
                  </Select>
                  <ValidationMessage field={"status"} />
                </Stack>
              </Grid>

              <FormMainAction
                cancel="Cancle"
                cancelClick={() => navigate(paths.slider)}
                submit="Update"
                submitClick={submitSlider}
                loading={loading}
              />

            </Grid>

        </Paper>

      </div>
    </>
  );
};
