import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Paper,
  TextField
} from '@mui/material';
import { paths } from "../../../constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminService } from "../adminService";
import { payloadHandler } from "../../../helpers/handler";
import { tooltipOptions } from "../../../constants/config";
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import { adminPayload } from "../adminPayload";
import { Profile } from "../../../shares/Profile";
import { formBuilder } from "../../../helpers/formBuilder";
import FormMainAction from "../../../shares/FormMainAction";
import { ValidationMessage } from '../../../shares/ValidationMessage';
import { endpoints } from '../../../constants/endpoints';

export const AdminUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState(adminPayload.update);
  const { admin } = useSelector(state => state.admin);
  const params = useParams();
  // const { translate } = useSelector(state => state.setting);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitAdmin = async () => {
    setLoading(true);

    const formData = formBuilder(payload, adminPayload.update);

    await adminService.update(dispatch, params.id, formData);

    setLoading(false);
}

  const loadingData = useCallback(async () => {
    setLoading(true);
    
    await adminService.show(dispatch, params.id);
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
      loadingData();
  }, [loadingData]);

  useEffect(() => {
    if (admin) {
      console.log(admin)
        setPayload(admin);
    }
  }, [admin])

  return (
    <>
      <div className=" grid">
        <div className="col-12">
          <Breadcrumb />
        </div>

        <Paper elevation={3} style={{ padding: 20, margin: 10 }}>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={1} justifyContent="center" alignItems="center" >
                  <Profile
                    payload={payload}
                    setPayload={setPayload}
                    // src={`${endpoints.image}/${payload.image.image}`}
                    field={'profile'}
                  />
                  <ValidationMessage field={"profile"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Name (required)</InputLabel>
                  <OutlinedInput
                    id="name"
                    type="text"
                    value={payload.name ? payload.name : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "name",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="name"
                    placeholder="Enter Your Name"
                  />
                  <ValidationMessage field={"name"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Email (required)</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    value={payload.email ? payload.email : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "email",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <ValidationMessage field={"email"} />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Phone (required)</InputLabel>
                  <OutlinedInput
                    id="phone"
                    type="text"
                    value={payload.phone ? payload.phone : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "phone",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="phone"
                    placeholder="Enter Your Phone"
                  />
                  <ValidationMessage field={"phone"} />
                </Stack>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Password (required)</InputLabel>
                  <OutlinedInput
                    id="password"
                    type="password"
                    value={payload.password ? payload.password : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "password",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="password"
                    placeholder="Enter Your Password"
                  />
                  <ValidationMessage field={"password"} />
                </Stack>
              </Grid>
  
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <InputLabel >Con Password (required)</InputLabel>
                  <OutlinedInput
                    id="confirm_password"
                    type="password"
                    value={payload.confirm_password ? payload.confirm_password : ""}
                    onChange={(e) =>
                      payloadHandler(
                        payload,
                        e.target.value,
                        "confirm_password",
                        (updateValue) => {
                          setPayload(updateValue);
                        }
                      )
                    }
                    name="confirm_password"
                    placeholder="Enter Your Con Password"
                  />
                  <ValidationMessage field={"confirm_password"} />
                </Stack>
              </Grid>

              <FormMainAction
                cancel="Cancle"
                cancelClick={() => navigate(paths.admin)}
                submit="Update"
                submitClick={submitAdmin}
                loading={loading}
              />

            </Grid>

        </Paper>

      </div>
    </>
  );
};
