// import { Box, Collapse, Divider, IconButton, List, Toolbar, Typography } from '@mui/material';
// import CssBaseline from '@mui/material/CssBaseline';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { AppBar, DrawerHeader, keys, Drawer } from '../../constants/config';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import LogoutIcon from '@mui/icons-material/Logout';

import { Outlet, useNavigate } from 'react-router-dom';
import HNKLOGO from "../../assets/images/enjoyLogo.png";
import { getData, removeAllData } from '../../helpers/localstorage';
import { useEffect, useState } from 'react';
import { Collapse, List, Typography } from '@mui/material';
import { keys } from '../../constants/config';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@emotion/react';
import { items } from './defaultPaths';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { paths } from '../../constants/paths';
import "./style.css";

export const DefaultLayout = () => {
    const theme = useTheme();
    // const [open, setOpen] = useState(false);
    const [user, setUser] = useState();
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const toogleExpand = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const token = getData(keys.API_TOKEN);
    const navigate = useNavigate();

    const logout = async () => {
        removeAllData();
        navigate(paths.adminLogout);
    }

    const getUserData = () => {
        const data = getData(keys.USER)
        setUser(data)
    }

    useEffect(() => {
        getUserData()
    }, [])

    useEffect(() => {
        if (!token) {
            navigate('/auth/login');
        }
    }, [token, navigate]);

    return (
        <>
            {token && (
                <div style={{ width: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        height: "60px",
                        background: "#0ca827",
                        color: "#fff"
                    }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <img style={{ width: "60px", height: "60px" }} src={HNKLOGO} alt="hnk" title='hnk' />
                            <Typography style={{ fontWeight: "600" }}>
                                Refresh Your Music Program
                            </Typography>
                        </div>
                        {/* <AppBar position="fixed" open={open} style={{ background: "#0CA827" }}>
                    </AppBar> */}
                        {/* <Drawer
                        variant="permanent"
                        open={open}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                            {items.map((nav, index) => (
                                <div key={index}>
                                    <ListItemButton onClick={() => {
                                        toogleExpand(index);
                                        if (nav.url) {
                                            navigate(nav.url)
                                        }
                                    }
                                    }>
                                        <ListItemIcon>
                                            {nav.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={nav.label} />
                                        {nav?.children?.length > 0 && (
                                            expandedIndex === index ? <ExpandLess /> : <ExpandMore />
                                        )}
                                    </ListItemButton>

                                    {
                                        nav?.children?.length > 0 && (
                                            <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        nav.children.map((child, index) => (
                                                            <ListItemButton
                                                                onClick={() => {
                                                                    navigate(child.url)
                                                                }}
                                                                key={child.key}
                                                                sx={{ pl: 4 }}
                                                            >
                                                                <ListItemIcon>
                                                                    {child.icon}
                                                                </ListItemIcon>
                                                                <ListItemText primary={child.label} />
                                                            </ListItemButton>
                                                        ))
                                                    }
                                                </List>
                                            </Collapse>
                                        )
                                    }
                                </div>
                            ))}
                        </List>
                        <Divider />
                        <List>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {user?.name}
                                </ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Logout
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Drawer> */}
                        {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <DrawerHeader />
                        <Outlet />
                    </Box> */}
                    </div>

                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", background: "#f4f4f4" }}>
                        <div style={{ width: "200px", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <List>
                                {items.map((nav, index) => (
                                    <div key={index}>
                                        <ListItemButton onClick={() => {
                                            toogleExpand(index);
                                            if (nav.url) {
                                                navigate(nav.url)
                                            }
                                        }
                                        }>
                                            <ListItemIcon>
                                                {nav.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={nav.label} />
                                            {nav?.children?.length > 0 && (
                                                expandedIndex === index ? <ExpandLess /> : <ExpandMore />
                                            )}
                                        </ListItemButton>

                                        {
                                            nav?.children?.length > 0 && (
                                                <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            nav.children.map((child, index) => (
                                                                <ListItemButton
                                                                    onClick={() => {
                                                                        navigate(child.url)
                                                                    }}
                                                                    key={child.key}
                                                                    sx={{ pl: 4 }}
                                                                >
                                                                    <ListItemIcon>
                                                                        {child.icon}
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={child.label} />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            )
                                        }
                                    </div>
                                ))}
                            </List>
                        </div>

                        <div style={{ width: "calc(100% - 220px)", height: "100vh", overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <div style={{width: "100%", height: "100vh", overflow: "scroll", display: "flex", flexDirection: "column", alignContent: "flex-start", justifyContent: "flex-start", padding: "20px"}}>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}