import { paths } from "../../constants/paths";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssistantIcon from '@mui/icons-material/Assistant';
import {
    EventAvailableRounded,
    Person,
    QueueMusic,
    SpatialAudioOff,
} from "@mui/icons-material";

export const items = [
    {
        key: "dashboard",
        label: "Dashboard",
        data: "Documents Folder",
        icon: <InsertChartIcon />,
        url: "/dashboard",
    },
    {
        key: "user",
        label: "User",
        icon: <Person />,
        url: paths.user,
    },
    {
        key: "episode",
        label: "Episode",
        icon: <AssistantIcon />,
        url: "/episode",
    },
    {
        key: "singer",
        label: "Singer",
        data: "Singer",
        icon: <SpatialAudioOff />,
        url: paths.singer
    },
    // {
    //     key: "3",
    //     label: "Song",
    //     data: "Song",
    //     icon: <LibraryMusic />,
    //     children: [
    //         {
    //             key: "3-1",
    //             label: "List",
    //             icon: <FormatListBulletedIcon />,
    //             url: paths.song,
    //         },
    //         {
    //             key: "3-2",
    //             label: "Create",
    //             icon: <AddIcon />,
    //             url: paths.songCreate,
    //         },
    //     ],
    // },
    {
        key: "4",
        label: "Genre",
        data: "Genre",
        icon: <QueueMusic />,
        url: paths.genre,
        // children: [
        //     {
        //         key: "4-1",
        //         label: "List",
        //         icon: <FormatListBulletedIcon />,
        //         url: paths.genre,
        //     },
        //     {
        //         key: "4-2",
        //         label: "Create",
        //         icon: <AddIcon />,
        //         url: paths.genreCreate,
        //     },
        // ],
    },
    // {
    //     key: "5",
    //     label: "Singer Slider",
    //     data: "Singer Slider",
    //     icon: <QueueMusic />,
    //     children: [
    //         {
    //             key: "5-1",
    //             label: "List",
    //             icon: <FormatListBulletedIcon />,
    //             url: paths.singerSlider,
    //         },
    //         {
    //             key: "5-2",
    //             label: "Create",
    //             icon: <AddIcon />,
    //             url: paths.singerSliderCreate,
    //         },
    //     ],
    // },
    {
        key: "6",
        label: "Event",
        data: "Event",
        icon: <EventAvailableRounded />,
        url: paths.eventSlider,
    },
    // {
    //     key: "6",
    //     label: "Outlet",
    //     data: "Outlet",
    //     icon: <Storefront />,
    //     children: [
    //         {
    //             key: "6-1",
    //             label: "List",
    //             icon: <FormatListBulletedIcon />,
    //             url: paths.outlet,
    //         },
    //         {
    //             key: "6-2",
    //             label: "Create",
    //             icon: <AddIcon />,
    //             url: paths.outletCreate,
    //         },
    //     ],
    // },
    // {
    //     key: "7",
    //     label: "Slider",
    //     icon: <AspectRatioIcon />,
    //     children: [
    //         {
    //             key: "7-1",
    //             label: "List",
    //             icon: <FormatListBulletedIcon />,
    //             url: paths.slider,
    //         },
    //         {
    //             key: "7-2",
    //             label: "Create",
    //             icon: <AddIcon />,
    //             url: paths.sliderCreate,
    //         },
    //     ],
    // },
    {
        key: "8",
        label: "Admin",
        icon: <AdminPanelSettingsIcon />,
        url: paths.admin,
    },
];
