import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';

export const NavigateId = ({ url, value }) => {
  const navigate = useNavigate();

  return (
    <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(url)}>
      {value}
    </Link>
  );
};
