export const genrePayload = {
    update: {
        name: "",
        rate: "",
        icon : "",
        color: "",
        status: "",
        auto_rate: ""
    },
    store: {
        name: "",
        rate: "",
        color: "",
        icon: ""
    },
    columns: [
        { id: "name", label: "Name" },
        { id: "icon", label: "Icon"},
        { id: "color", label: "Color" },
        { id: "rate", label: "Rate" },
        { id: "show_rate", label: "Rate %" },
        { id: "auto_rate", label: "Auto Rate"},
        { id: "status", label: "Status"},
        
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "id,name,icon,color,rate,auto_rate,status",
        search: "",
        order: "id",
        sort: "DESC",
        value: "",
        start_date: "",
        end_date: "",
    },
};
