export const singerPayload = {
    update: {
        name: "",
        profile: "",
        image: "",
        slider_image: "",
        slider_description: "",
        detail_title: "",
        cover_photo: "",
        show_slider: "",
        invite_video: "",
        status: "",
    },
    store: {
        name: "",
        profile: "",
        image: "",
        show_slider: "",
        slider_image: "",
        slider_description: "",
        detail_title: "",
        cover_photo: "",
    },
    columns: [
        { id: "name", label: "Name", minWidth: 250 },
        { id: "profile", label: "Profile", minWidth: 150 },
        { id: "slider_description", label: "Slider Description", minWidth: 300 },
        { id: "detail_title", label: "Detail Title", minWidth: 300 },
        { id: "show_slider", label: "Show Slider", minWidth: 300 },
        { id: "status", label: "Status", minWidth: 150 }
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "name",
        search: "",
        order: "id",
        sort: "DESC",
        value: "",
        start_date: "",
        end_date: "",
    },
};
