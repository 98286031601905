export const outletPayload = {
    update: {
        name: "",
        phone: "",
        address: "",
        date: "",
        time: "",
        promotion: "",
        promo_description: "",
        latitude: "",
        longitude: "",
        image: "",
        branch: "",
        month: "",
        activation_date: "",
        music_band: ""
    },
    store: {
        name: "",
        phone: "",
        address: "",
        date: "",
        time: "",
        promotion: "",
        promo_description: "",
        latitude: "",
        longitude: "",
        status: "",
        image: "",
        branch: "",
        month: "",
        activation_date: "",
        music_band: ""
    },
    columns: [
        { id: "name", label: "Name", minWidth: 250 },
        { id: "image", label: "Image", minWidth : 170 },
        { id: "phone", label: "Phone", minWidth: 150 },
        { id: "address", label: "Address", minWidth: 250 },
        { id: "date", label: "Date", minWidth: 150 },
        { id: "time", label: "Time", minWidth: 150 },
        { id: "promotion", label: "Promotion", minWidth: 150 },
        {
            id: "promo_description",
            label: "Promotion Description",
            minWidth: 150,
        },
        { id: "latitude", label: "Latitude", minWidth: 150 },
        { id: "longitude", label: "Longitude", minWidth: 150 },
        // { id: "branch", label: "Branch", minWidth: 150 },
        // { id: "month", label: "Month", minWidth: 150 },
        // { id: "activation_date", label: "Activation Date", minWidth: 150 },
        // { id: "music_band", label: "Music Band", minWidth: 150 },
        { id: "status", label: "Status", minWidth: 150 },
    ],
    paginateParams: {
        page: 1,
        per_page: 10,
        columns: "name",
        search: "",
        order: "id",
        sort: "DESC",
        value: "",
        start_date: "",
        end_date: "",
    },
};
