import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Breadcrumb } from '../../../shares/Breadcrumbs'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePaginationActions, emptyRows } from '../../../constants/config';
import { Avatar, Box, TableHead, TableSortLabel, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPaginate } from '../singerSlice';
import { TableSearch } from '../../../shares/TableSearch';
import { singerService } from '../singerService';
import { singerPayload } from '../singerPayload';
import { NavigateId } from '../../../shares/NavigateId';
import { paths } from '../../../constants/paths';
import { Status } from "../../../shares/Status";
import { endpoints } from '../../../constants/endpoints';

export const SingerList = () => {

  const { singers, paginateParams } = useSelector(state => state.singer);
  const dispatch = useDispatch();

  const [total, setTotal] = useState(0);
  const [columnIds, setColumnIds] = useState('');
  const [sort, setSort] = useState(true);
  const singerColumns = useRef(singerPayload.columns);
  const [columns, setColumns] = useState(singerPayload.columns);

  /**
  * Event - Paginate Page Change
  * @param {*} event 
  */
  const onPageChange = (event, newPage) => {
    dispatch(
      setPaginate({
        ...paginateParams,
        page: newPage,
      })
    );
  };

  /**
  * Event - Paginate Row Per Page Change
  * @param {*} event 
  */
  const onRowPerPageChange = (event) => {
    dispatch(
      setPaginate({
        ...paginateParams,
        page: 1,
        per_page: parseInt(event.target.value, 10)
      })
    );
  };

  /**
 * Event - Sorting
 * @param {*} event 
 */
  const onHandleSort = (event, label) => {
    setSort(!sort);
    dispatch(
      setPaginate({
        ...paginateParams,
        sort: sort ? 'ASC' : 'DESC',
        order: label?.toLowerCase()
      })
    );
  }

  const ColumnSortHandle = (id) => {
    if (columnIds === id) {
      return sort ? 'asc' : 'desc'
    }
  }

  /**
 * Event - Search
 * @param {*} event 
 */
  const onSearchChange = (event) => {
    dispatch(
      setPaginate({
        ...paginateParams,
        search: event,
      })
    );
  };

  const loadingData = useCallback(async () => {
    const result = await singerService.index(dispatch, paginateParams);
    if (result.status === 200) {
      setTotal(result?.data?.total ? result.data.total : result.data.length);
    }
  }, [dispatch, paginateParams])

  useEffect(() => {
    loadingData();
  }, [loadingData])

  const IconRender = ({ dataSource }) => {
    const [data, setData] = useState(null);
    useEffect(() => {
      if(dataSource) {
        setData(dataSource);
      }
    },[dataSource]);

    return (
      <>
        {data && (
          <Avatar alt="icon" src={data ? `${endpoints.image}/${data.image}` : null} />
        )}
      </>
      )
  }

  return (
    <div>

      <Breadcrumb />

      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>

        <TableContainer sx={{ maxHeight: 540 }}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={12}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    marginTop={1}
                  >

                    <TableSearch paginateParams={paginateParams} onSearchChange={onSearchChange} />

                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                {singerColumns.current.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={true}
                      direction={ColumnSortHandle(column.id)}
                      onClick={(e) => {
                        onHandleSort(e, column.id);
                        setColumnIds(column.id);
                      }}
                    >
                     <Typography style={{fontWeight: "600"}}>  {column.label} </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {singers.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                          const value = row[column.id];
                          
                          const switchCase = ({ column, value }) => {
                              switch (column.id) {
                                  case "name":
                                      return (
                                          <NavigateId
                                              url={`${paths.singer}/${row.id}`}
                                              value={value}
                                          />
                                      );
                                  case "profile":
                                      return <Avatar alt="icon" src={`${endpoints.image}/${value}`} />
                                  case "show_slider":
                                    return <Status status={value} />
                                  case "status":
                                    return(
                                      <Status status={value} />
                                    )
                                  default:
                                      return value;
                              }
                          };

                          return (
                              <TableCell key={column.id} align={column.align}>
                                  {switchCase({ column, value })}
                              </TableCell>
                          );
                      })}
                  </TableRow>
                  );
                })}
              {emptyRows(paginateParams.page, paginateParams.rowsPerPage, singers) > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'right'}
          sx={{
            width: '100%'
          }}
        >
          <TableRow>
            <TableCell>
              <TablePagination
                sx={{
                  width: '100%'
                }}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={total}
                rowsPerPage={paginateParams.per_page}
                page={paginateParams ? paginateParams.page - 1 : 0}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableCell>
          </TableRow>
        </Box>

      </Paper>

    </div>
  )
}
